<template>
  <footer>
    <div class="container box-footer text-center">
      <img src="../assets/footer_logo.png" alt="logo yolanda" class="logo-footer">
      <div class="d-flex align-items-center justify-content-center pt-md-0 pt-4">
        <p class="title-5 grey-dark mb-0 me-3">
          Sígueme
        </p>
        <a href="https://www.instagram.com/yolandaarayanutri/" target="_blank"><img src="../assets/icons/insta_icon.png" alt="logo instagram" class="logo-rrss"></a>
      </div>
    </div>
    <div class="text-md-end text-center container">
      <hr>
      <p class="p-10 m-0 py-2 dark-grey">Desarrollado y diseñado por <a class="purple" href="https://www.vanilla.cl">Vanilla Diseño</a></p>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'FooterComp',
}
</script>

<style>

</style>