<template>
  <div class="card card-servicio" :class="color">
    <div class="row g-0 align-items-center">
      <div class="col-lg-3 col-12 text-center" :class="display">
        <img :src="getImagePath(servicio.img)" class="img-fluid img-servicio" alt="...">
      </div>
      <div class="col-lg-5 col-12">
        <div class="card-body">
          <h4 class="card-title title-4 turq">{{ servicio.nombre }}</h4>
          <p class="card-text little-sub">{{ servicio.modalidad }}</p>
          <p class="card-text p-15">{{ servicio.descripcion }}</p>
        </div>
      </div>
      <div class="col-lg-4 col-12 card-caract">
        <div class="d-flex">
          <TurqPill :class="'turq-pill'" :PillText="'Duración'"/>
          <TurqPill :class="'grey-pill'" :PillText="servicio.duracion"/>
        </div>
        <div class="d-flex">
          <TurqPill :class="'turq-pill'" :PillText="'Ubicación'"/>
          <TurqPill :class="'grey-pill'" :PillText="servicio.ubicacion"/>
        </div>
        <div class="d-flex">
          <TurqPill :class="'turq-pill'" :PillText="'Precio'"/>
          <TurqPill :class="'grey-pill'" :PillText="servicio.precio"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TurqPill from '../components/TurqPill.vue'

export default {
  name: 'CardServicio',
  props: {
    servicio: Object,
    color: String,
    display: String,
  },
  components: {
    TurqPill,
  },
  methods: {
    getImagePath(imagen) {
      try {
        return require(`@/assets/img/${imagen}`);
      } catch (e) {
        console.error(`La imagen ${imagen} no se encontró en la ruta especificada.`);
        return '';
      }
    },
  }
}

</script>
