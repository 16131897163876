<template>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container">
      <a class="navbar-brand" href="#"><img src="../assets/nav_logo.png" alt="logo yolanda"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse pt-lg-0 pt-3" id="navbarNav">
        <ul class="navbar-nav ms-lg-auto text-lg-end text-center">
          <li class="nav-item">
            <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'sobremi'}">Sobre mí</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'servicios'}">Terapias y Talleres</router-link>
            
          </li>
          <li class="nav-item">
            <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'arte-alma'}">Arte y Nutrición</router-link>
            
          </li>
          <li class="nav-item">
            <router-link class="nav-link p-16" aria-current="page" :to="{name: 'home', hash: '#'+'testimonios'}">Testimonios</router-link>
          </li>
          <li class="nav-item">
            <TurqButton :router="`#contacto`" :buttonText="'escríbeme'" class="m-lg-0 m-2"/>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import TurqButton from '@/components/TurqButton.vue'

export default {
  name: 'NavBar',
  props: {
    msg: String
  },
  components: {
    TurqButton,
  }
}
</script>

<style>

</style>