<template>
  <div class="rounded-pill pill p-15" :class="ColorPill">
    {{PillText}}
  </div>
</template>

<script>
export default {
  name: 'TurqPill',
  props: {
    PillText: {
      type: String,
      default: 'Duración'
    },
    ColorPill: {
      type: String,
    }
  }
}
</script>