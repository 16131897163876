// index.js en el store
import { createStore } from 'vuex';
import servicios from './servicios.json';
import testimonios from './testimonios.json';

const store = createStore({
  state: {
    counter: 0,
    servicios: servicios,
    testimonios: testimonios,
  },
  getters: {
    allServicios: state => state.servicios
  },
  mutations: {},
  actions: {},
  modules: {}
});

export default store;