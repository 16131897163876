<template>
  <button 
    type="button"
    class="btn rounded-pill" 
    :class="colorBtn">
    <a href="https://wa.me/56978088086?text=Hola!%20Me%20gustaría%20agendar%2020%20minutos" target="_blank">{{buttonText}}</a>
  </button>
</template>

<script>

export default {
  name: 'TurqButtonWsp',
  props: {
    router: {
      type: Object,
      default() {
        return { message: 'hello' }
      }
    },
    buttonText: {
      type: String,
      default: 'Button'
    },
    colorBtn: {
      type: String,
      default: 'btn-turq'
    },
  }
}
</script>