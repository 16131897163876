<template>
  <div id="carouselExampleCaptions" class="carousel carousel-dark slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button 
        v-for="(testimonio, index) in testimonios" 
        :key="index" 
        type="button" 
        :data-bs-target="'#carouselExampleCaptions'" 
        :data-bs-slide-to="index" 
        :class="{ active: index === activeIndex }"
        :aria-current="index === activeIndex ? 'true' : undefined"
        :aria-label="'Slide ' + (index + 1)"
        @click="setActiveIndex(index)">
      </button>
    </div>
    <div class="carousel-inner">
      <div 
        v-for="(testimonio, index) in testimonios" 
        :key="index"  
        :class="['carousel-item', { active: index === activeIndex }]" 
      >
        <div class="row mb-5">
          <div class="col-sm-6 col-12">
            <p class="p-40 bold italic light-purple titulo-test">
              {{ testimonio.titulo  }}
            </p>
          </div>
          <div class="col-sm-6 col-12">
            <p class="p-18 italic light testimonio">
            {{ testimonio.testimonio  }}
            </p>
            <p class="p-18 nom-test">
              {{ testimonio.nombre  }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CarouselTestimonios',
  data() {
    return {
      activeIndex: 0,
    };
  },
  props: {
    testimonio: Object,
    },
  computed: {
    ...mapState(['testimonios']),
  },
  mounted() {
    this.startCarousel();
  },
  beforeUnmount() {
    this.stopCarousel();
  },
  methods: {
    startCarousel() {
      this.carouselInterval = setInterval(() => {
        this.activeIndex = (this.activeIndex + 1) % this.testimonios.length;
      }, 5000); // Cambia de testimonio cada 5 segundos
    },
    stopCarousel() {
      clearInterval(this.carouselInterval);
    },
    setActiveIndex(index) {
      this.stopCarousel();
      this.activeIndex = index;
      //this.startCarousel();
    }
  }
}
</script>