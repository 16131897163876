<template>
  <NavBar/>
  <header class="container-fluid d-inline-block align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-12 header-text">
          <h1 class="title-1">Bienestar integral para la <br/><span>nutrición de cuerpo y alma</span></h1>
          <p class="subtitle white">Te acompaño en tu camino hacia una vida más saludable y equilibrada.</p>
          <TurqButtonWsp :buttonText="'¡agendemos 20 min gratis!'"/>
        </div>
        <div class="col-md-6 col-12 yoli-cont">
          <img src="../assets/img/yoli_header.png" alt="foto yolanda" class="img-fluid yoli-header">
        </div>
      </div>
    </div>
  </header>
  <div class="header-waves">
    <img src="../assets/waves/purple_waves.png" alt="" class="waves"> 
  </div>
  <div class="container" id="beneficios">
    <div class="row text-center">
      <div class="col-md-4 col-12 step-cont">
        <div class="card align-items-center text-center">
          <div class="icono-benef">
            <img src="../assets/icons/enfoque.png" alt="">
          </div>
          <div class="card-body text-center">
            <p class="card-title title-3 purple">Enfoque Integral y Empático</p>
            <p class="card-text p-18 light">Abordo la nutrición considerando a la persona como un todo, atendiendo tanto sus necesidades <span class="bold">físicas como emocionales</span>, con una escucha activa y sin prejuicios, promoviendo una relación cercana y de respeto.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12 step-cont">
        <div class="card align-items-center text-center">
          <div class="icono-benef">
            <img src="../assets/icons/bienestar.png" alt="">
          </div>
          <div class="card-body text-center">
            <p class="card-title title-3 purple mx-5">Bienestar Holístico</p>
            <p class="card-text p-18 light">Combino la nutrición del cuerpo con la <span class="bold">nutrición del Alma a través del Arte</span>, buscando siempre el bienestar total de mis consutantes. Si es necesario, derivo a otros terapeutas para un apoyo integral.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12 step-cont">
        <div class="card align-items-center text-center">
          <div class="icono-benef">
            <img src="../assets/icons/personalizacion.png" alt="">
          </div>
          <div class="card-body text-center">
            <p class="card-title title-3 purple">Personalización y
              Seguimiento</p>
            <p class="card-text p-18 light">Elaboro planes alimentarios detallados basados en un <span class="bold">análisis exhaustivo y en la revisión del caso clínico</span>, y mantengo una comunicación fluida y de apoyo continuo con cada consultante.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="turq-waves">
    <img src="../assets/waves/turq_waves.png" alt="" class=""> 
  </div>
  <div id="sobremi">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-12 text-center">
          <img src="../assets/img/yoli_sobre_mi.png" alt="" class="yoli-sobremi img-fluid">
        </div>
        <div class="col-md-6 col-12 text-start ps-md-5">
          <h2 class="title-2 purple">
            Sobre mí
          </h2>
          <p class="hand-40 purple">
            ¡Hola! Soy Yolanda
          </p>
          <p class="p-18 light">
            Una mujer multifacética apasionada por la nutrición y el arte de tejer. Titulada como nutricionista de la Universidad de Concepción con <span class="bold">más de 18 años de experiencia</span> y 6 años dedicados al tejido en telar, ofrezco una <span class="bold">visión integral del bienestar</span> que conecta cuerpo y alma. Nací en Quillota donde vivo con mi marido Edu, mis dos hijos maravillosos: Anto y Pancho y mis adorables perritos.
          </p>
          <p class="p-18 light">
            A lo largo de mi carrera, he acumulado numerosos cursos y diplomados en nutrición y salud, siempre con el objetivo de <span class="bold">ayudar a las personas a conectar con su mejor versión y vivir en armonía.</span>
          </p>
          <p class="p-18 light">
            Me encanta comunicar, enseñar y ver cómo las vidas pueden transformarse de manera positiva.
          </p>
        </div>
      </div>
    </div>
    
  </div>
  <div class="purple-waves invertir">
    <img src="../assets/waves/turq_waves.png" alt="" class=""> 
  </div>
  <div id="servicios" class="container">
    <div class="text-center">
      <p class="hand-30">
        Me encantaría apoyarte y acompañarte con
      </p>
      <h2 class="title-2 purple">
        Un servicio especial para tí
      </h2>
    </div>
    <div class="cards-servicios">
      <CardServicio :servicio="servicios[0]" :color="'grey-style'"/>
      <CardServicio :servicio="servicios[1]" :color="'purple-style'"/>
      <CardServicio :servicio="servicios[2]" :color="'grey-style'"/>
      <CardServicio :servicio="servicios[3]" :color="'purple-style'"/>
    </div>
    <TurqButtonWsp :buttonText="'¡agendemos 20 min gratis!'"/>
  </div>
  <div class="header-waves invertir">
    <img src="../assets/waves/purple_waves.png" alt="" class="waves"> 
  </div>
  <div id="arte-alma">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-12 order-1 order-md-0">
          <h2 class="hand-50 light-purple">El arte como</h2>
          <h2 class="title-1 light-purple">nutrición del alma</h2>
          <p class="p-18 light italic white">
            “La nutrición y el arte se entrelazan en mi vida para ofrecer un enfoque holístico del bienestar. La nutrición permite alimentar nuestro cuerpo de manera sana y consciente, mientras que el tejido en telar nutre nuestra creatividad y salud mental. Ambas disciplinas buscan el bienestar integral, nutriendo tanto el cuerpo como el alma. El arte de tejer no solo mejora la autoestima y estimula hormonas de la felicidad, sino que también se convierte en una forma de arteterapia, permitiendo una expresión emocional y espiritual.”
          </p>
          <p class="p-18 white mb-0">
            Yolanda Araya Pérez
          </p>
          <p class="p-18 light white">
            Nutricionista y Tejedora
          </p>
          <div class="card mb-3 card-servicio purple-style">
            <div class="row g-0 align-items-center">
              <div class="col-lg-7 col-12">
                <div class="card-body">
                  <h4 class="card-title title-4 turq">{{ servicios[4].nombre }}</h4>
                  <p class="card-text little-sub">{{ servicios[4].modalidad }}</p>
                  <p class="card-text p-15">{{ servicios[4].descripcion }}</p>
                </div>
              </div>
              <div class="col-lg-5 col-12 card-caract">
                <div class="d-flex justify-content-lg-center">
                  <TurqPill :class="'turq-pill'" :PillText="'Duración'"/>
                  <TurqPill :class="'grey-pill'" :PillText="servicios[4].duracion"/>
                </div>
                <div class="d-flex justify-content-lg-center">
                  <TurqPill :class="'turq-pill'" :PillText="'Ubicación'"/>
                  <TurqPill :class="'grey-pill'" :PillText="servicios[4].ubicacion"/>
                </div>
                <div class="d-flex justify-content-lg-center">
                  <TurqPill :class="'turq-pill'" :PillText="'Precio'"/>
                  <TurqPill :class="'grey-pill'" :PillText="servicios[4].precio"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 order-0 order-md-1">
          <img src="../assets/img/yoli_alma.png" alt="" class="yoli-alma img-fluid">
        </div>
      </div>
    </div>
  </div>
  <div class="turq-waves">
    <img src="../assets/waves/turq_waves.png" alt="" class=""> 
  </div>
  <div id="cta" class="text-center">
    <div class="container">
      <p class="hand-40">¿Quieres transformar tu vida</p>
      <h2 class="title-2 purple">y alcanzar el bienestar integral?</h2>
      <p class="p-18 pb-3">Agenda tu consulta o taller hoy mismo</p>
      <div class="d-inline">
        <TurqButton :router="`#contacto`" :colorBtn="'btn-white'" :buttonText="'me interesa'"/>
        <TurqButtonWsp :colorBtn="'btn-purple'" :buttonText="'contactar por whatsapp'"/>
      </div>
    </div>
  </div>
  <div class="purple-waves invertir">
    <img src="../assets/waves/turq_waves.png" alt="" class=""> 
  </div>
  <div id="testimonios" class="d-grid justify-items-center">
    <div class="box-testimonios text-center">
      <h2 class="title-1 purple">
        Lo que dicen mis pacientes
      </h2>
      <p class="p-18 mb-5">
        de mi y de mis terapias
      </p>
      <CarouselTestimonios/>
    </div>
  </div>
  <div id="contacto" class="container">
    <div class="row">
      <div class="col-md-4 col-12">
        <h2 class="title-1 purple">Escríbeme</h2>
        <p class="p-18 light">Espero poder acompañarte en tu camino hacia una vida más saludable y equilibrada</p>
        <div class="box-datos">
          <img src="../assets/icons/mail.png" alt="icono correo">
          <a class="p-16 light" href="mailto: yolanda.arayaperez@gmail.com">yolanda.arayaperez@gmail.com</a>
        </div>
        <div class="box-datos">
          <img src="../assets/icons/telefono.png" alt="icono telefono">
          <a class="p-16 light" href="">+569 7808 8086</a>
        </div>
        <div class="box-datos">
          <img src="../assets/icons/ubicacion.png" alt="icono ubicacion">
          <a class="p-16 light">Quillota, Región de Valparíso</a>
        </div>
      </div>
      <div class="col-md-8 col-12 mt-md-0 mt-2">
        <iframe 
          data-tally-src="https://tally.so/embed/w7DGO2?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" 
          loading="lazy" 
          width="100%" 
          height="374" 
          frameborder="0" 
          marginheight="0" 
          marginwidth="0" 
          title="Formulario Landingpage">
        </iframe>
      </div>
    </div>
  </div>
  <FooterComp/>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import TurqButton from '@/components/TurqButton.vue'
import TurqButtonWsp from '@/components/TurqButtonWsp.vue'
import { mapState } from 'vuex';
import CardServicio from '@/components/CardServicio.vue'
import TurqPill from '../components/TurqPill.vue'
import CarouselTestimonios from '../components/CarouselTestimonios.vue'
import FooterComp from '../components/FooterComp.vue'

export default {
  name: 'HomeView',
  components: {
    FooterComp,
    CarouselTestimonios,
    CardServicio,
    TurqButton,
    TurqButtonWsp,
    NavBar,
    TurqPill,
  },
  computed: {
    ...mapState(['servicios'])
  },
  mounted() {
    window.Tally.loadEmbeds()
  }
}
</script>
